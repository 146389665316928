import React from "react";
import { Helmet } from "react-helmet";

export const wrapRootElement = ({ element }) => {
  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          src="https://cdn.weglot.com/weglot.min.js"
        ></script>
        <script>{`
              var weglot_timeout = null;
              weglot_timeout = setInterval(() => {
                if (typeof Weglot !== "undefined") {
                  clearInterval(weglot_timeout);
                  Weglot.initialize({ api_key: "${process.env.GATSBY_WEGLOT_APIKEY}" });
                }
              }, 1000);
        `}</script>
        <style>
          {`.wg-default, .wg-default .country-selector {right: auto !important; left: 20px;}`}
        </style>
      </Helmet>
      {element}
    </React.Fragment>
  );
};
